import React, { useContext, useMemo, useState } from "react";
import { Breadcrumb, Col, Form, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router";
import { AuditPlanningTemplatebutton } from "../AuditPlanningTemplate";
import {
  useGetAuditPlan,
  useGetAuditPlansInGroup,
} from "utils/connectors/auditPlanningConnectors";
import { AttributeDateDisplay } from "common/FormExtras";
import { usePermissions } from "common/Permissions";
import { StickyHeader } from "common/StickyHeader";
import GenerateReport from "utils/docx-export/GenerateReport";
import { auditPlanningReportSchema } from "../auditPlanningReportSchema";
import {
  AuditPlanTemplateInput,
  AuditPlanTemplateMutation,
  AuditPlanTemplateMutationVariables,
  CloneAuditPlanMutation,
  CloneAuditPlanMutationVariables,
  GetAuditPlanningQuery,
  ModifyAuditPlanningMutation,
  ModifyAuditPlanningMutationVariables,
  ViewType,
} from "API";
import { MutationTuple, useMutation, gql, ApolloError } from "@apollo/client";
import Loader from "common/Loader";
import { auditPlanTemplate, cloneAuditPlan, modifyAuditPlanning } from "graphql/mutations";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import TitleEdit from "common/TitleEdit";
import { RelatedArtifacts } from "common/RelatedItems";
import AuditPlanningPageAttributes from "./AuditPlanningPageAttributes";
import AuditPlanningPageDetails from "./AuditPlanningPageDetails";
import AuditPlanningPageResults from "./AuditPlanningPageResults";
import AuditPlanningPageScope from "./AuditPlanningPageScope";
import RelatedItemsTable, {
  getRelatedItems,
} from "common/RelatedItems/RelatedItemsTable";
import { faSpinner } from "@fortawesome/free-solid-svg-icons"; // Import the spinner icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSetTitle from "utils/useSetTitle";
import { useMutationWithManualUpdate } from "utils/useMutationWithManualUpdate";
import * as yup from "yup";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import ConvertItem from "common/ConvertItem";

export const AuditPlanningContext = React.createContext<{
  auditPlan: Partial<GetAuditPlanningQuery["getAuditPlanning"]>;
  updateAuditPlan:
    | MutationTuple<
        ModifyAuditPlanningMutation,
        ModifyAuditPlanningMutationVariables
      >[0]
    | (() => Promise<void>);
  viewType: string;
}>({
  auditPlan: {},
  updateAuditPlan: () => new Promise<void>((resolve) => resolve()),
  viewType: "",
});

export const useAuditPlanContext = () => useContext(AuditPlanningContext);

export const AuditPlanningPage = () => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { auditPlan, refetch } = useGetAuditPlan(id);
  useSetTitle(`Audit${(auditPlan?.title && ` - ${auditPlan.title}`) || ""}`);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const rightSidebarCollapsed = localStorage.getItem("rightsidebar-collapsed");
  const [isExpanded, setIsExpanded] = useState(!rightSidebarCollapsed);
  const { auditPlans, auditPlansTemplates }: any = useGetAuditPlansInGroup();
  const { groupRole, group } = usePermissions();
  const [updateAuditPlan] = useMutation<
    ModifyAuditPlanningMutation,
    ModifyAuditPlanningMutationVariables
  >(gql(modifyAuditPlanning), {
    onCompleted: () => {
      ToastifyQueue("Audit Plan Updated Successfully.", "success");
      refetch();
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const updateAuditType = async (viewType: ViewType) => {
    setLoading(true);
    await updateAuditPlan({
      variables: {
        input: [
          {
            id,
            groupID: group.id,
            viewType,
          },
        ],
      },
    }).then(refetch);
    setLoading(false);
  };

  const onComplete = (response) => {
    const auditPlanTemplate =
      response?.CloneAuditPlan ||
      response?.AuditPlanTemplate;
    const auditPlanTemplateId =
      (auditPlanTemplate?.[0] &&
        JSON.parse(auditPlanTemplate?.[0])?.id) ??
      null;
    if (auditPlanTemplateId) {
      history.push(`/audits/${auditPlanTemplateId}`);
    } else {
      refetch();
    }
  }

  const [CreateAuditPlanTemplate] = useMutationWithManualUpdate<
    AuditPlanTemplateMutation,
    AuditPlanTemplateMutationVariables
  >(
    gql(auditPlanTemplate),
    {
      mutationKey: "AuditPlanTemplate",
      currentData: auditPlan,
      onCompleted: (response) => {
        onComplete(response);
      },
      onError: ({ message }: ApolloError) => {
        ToastifyQueue(message, "danger");
      },
    },
    { message: "Audit Plan Template Created Successfully" }
  );

  const [CloneAuditPlan] = useMutationWithManualUpdate<
    CloneAuditPlanMutation,
    CloneAuditPlanMutationVariables
  >(
    gql(cloneAuditPlan),
    {
      mutationKey: "CloneAuditPlan",
      currentData: auditPlan,
      onCompleted: (response) => {
        onComplete(response);
      },
      onError: ({ message }: ApolloError) => {
        ToastifyQueue(message, "danger");
      },
    },
    { message: "Audit Plan Cloned Successfully" }
  );

  const AuditPlanTemplateSchema = useMemo(() => {
    return yup.object<Partial<AuditPlanTemplateInput>>({
      auditPlanID: yup.string().default(auditPlan?.id).required().label("id"),
      title: yup
        .string()
        .default(
          `${auditPlan?.title} ${
            auditPlan?.isTemplate === true ? "" : "Template"
          }`
        )
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          `${
            auditPlan?.isTemplate === true
              ? "Audit Plan"
              : "Audit Plan Template"
          } title already exists!`,
          (value) => {
            if (!value) return true;
            return !isUniqueTitle(
              auditPlan?.isTemplate === true ? auditPlans : auditPlansTemplates,
              value
            );
          }
        ),
    });
  }, [auditPlan, auditPlans]);

  return (
    (!auditPlan && <Loader />) ||
    (auditPlan && (
      <AuditPlanningContext.Provider
        value={{ auditPlan, updateAuditPlan, viewType: auditPlan?.viewType }}
      >
        <StickyHeader>
          <Col xs="12">
            <Breadcrumb>
              <LinkContainer to="/audits" exact>
                <Breadcrumb.Item>Audits</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active={false}>
                {auditPlan?.title}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Row>
            <Col xs="6">
              <TitleEdit
                item={auditPlan}
                refetch={refetch}
                mutation={modifyAuditPlanning}
                userRole={groupRole}
                items={
                  !auditPlan.isTemplate === true
                    ? auditPlans
                    : auditPlansTemplates
                }
                type="audit plan"
              />
              <Nav variant="tabs" style={{ display: "inline-flex" }}>
                <LinkContainer exact to={`/audits/${id}/details`}>
                  <Nav.Link>Details</Nav.Link>
                </LinkContainer>
                <LinkContainer to={`/audits/${id}/relatedItems`}>
                  <Nav.Link>Related Items</Nav.Link>
                </LinkContainer>
              </Nav>
            </Col>
            <Col xs="6">
              <div style={{ marginLeft: "200px" }}>
                {" "}
                <AttributeDateDisplay item={auditPlan} />{" "}
              </div>
            </Col>
          </Row>
        </StickyHeader>
        {tab === "details" && (
          <Row className="mt-205">
            <Col sm="8">
              <Form>
                <Form.Check
                  label="Standard"
                  inline
                  style={{ width: "auto" }}
                  name="group1"
                  type="radio"
                  value="Standard"
                  checked={auditPlan?.viewType === ViewType.Standard}
                  disabled={loading}
                  onChange={async (e) => {
                    updateAuditType(ViewType.Standard);
                  }}
                />
                <Form.Check
                  label="Advanced"
                  inline
                  style={{ width: "auto" }}
                  name="group1"
                  type="radio"
                  value="Advanced"
                  checked={
                    auditPlan?.viewType === ViewType.Advanced ||
                    auditPlan?.viewType === null
                  }
                  disabled={loading}
                  onChange={async (e) => {
                    updateAuditType(ViewType.Advanced);
                  }}
                />
                {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </Form>
            </Col>
            <Col sm="4" className="float-right">
              {tab === "details" && (
                <span className="d-block text-right">
                  {groupRole !== "read" && (

                    <ConvertItem
                    item={auditPlan}
                    title={
                      auditPlan?.isTemplate === true
                        ? "Clone as Audit"
                        : "Clone as Template"
                    }
                    updateItemMutation={updateAuditPlan}
                    templateMutation={CreateAuditPlanTemplate}
                    cloneMutation={CloneAuditPlan}
                    itemSchema={AuditPlanTemplateSchema}
                    />
                  )}
                  <GenerateReport
                    type="Single"
                    item={auditPlan}
                    schema={auditPlanningReportSchema()}
                  />
                </span>
              )}
            </Col>
            <Col sm={12} xl={isExpanded ? 12 : 8}>
              <AuditPlanningPageAttributes />
              <AuditPlanningPageDetails />
              <AuditPlanningPageScope />
              <AuditPlanningPageResults />
            </Col>
            <div className="aside">
              <RelatedArtifacts
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                refetchItem={refetch}
              />
            </div>
          </Row>
        )}
        {tab === "relatedItems" && (
          <RelatedItemsTable
            module=""
            relatedItems={getRelatedItems(
              auditPlan?.auditPlanningRelatedItems?.items
            )}
            moduleSortOrder="auditPlanningRelatedItemsSortOrder"
          />
        )}
      </AuditPlanningContext.Provider>
    )) ||
    "You don't have permission to view this item"
  );
};
