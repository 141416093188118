import React, { useEffect, useState, useMemo } from "react";

import { Nav } from "react-bootstrap";

import {
  faFirstAid,
  faBug,
  faRadiationAlt,
  faPeopleArrows,
  faClipboardList,
  faListAlt,
  faCog,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import "./LeftNavBar.scss";

import DecipherRisk from "./DecipherRisk.png";
import DecipherRiskM from "./DecipherRiskM.png";

import NavItem from "./NavItem";
import { useAuthState } from "utils/AuthState";
import { usePermissions } from "common/Permissions";
import { Link } from "react-router-dom";

const defaultLeftsidePreferences = {
  Audits: "true",
  Assessments: "true",
  Issues: "true",
  Risk: "true",
  ActionPlans: "true",
  "System Management": "true",
};
const LeftNavBarV2: React.FC = () => {
  const permissions = usePermissions();
  const { user, userMeta } = usePermissions();
  const authState = useAuthState();
  const { userAttributes } = authState;
  const _leftsidePreferences = userMeta?.leftsidePreferences;
  const loadedPreLeftsidePreferences = useMemo(
    () =>
      _leftsidePreferences
        ? JSON.parse(_leftsidePreferences)
        : defaultLeftsidePreferences,
    [_leftsidePreferences]
  );
  const [_preLeftsidePreferences, _setPreLeftsidePreferences] = useState(
    loadedPreLeftsidePreferences
  );

  console.log("LOADING USER META", { userMeta });

  const keys = Object.keys(_preLeftsidePreferences);
  const filtered = keys.filter(function (key) {
    return _preLeftsidePreferences[key] === "true";
  });
  useEffect(() => {
    if (!loadedPreLeftsidePreferences) return;
    _setPreLeftsidePreferences(Object.assign(loadedPreLeftsidePreferences));
  }, [loadedPreLeftsidePreferences]);

  return (
    <Nav className="navbar-default navbar-static-side" role="navigation">
      {" "}
      <div className="sidebar-collapse" style={{ height: "100%" }}>
        <ul className="nav metismenu" id="side-menu">
          <Link to="/dashboard">
            <img
              src={DecipherRisk}
              alt="Decipher Risk Logo"
              className="Risklogo"
              style={{ marginLeft: "30px" }}
            />
            <img
              src={DecipherRiskM}
              alt="Decipher Risk Logo"
              className="Risklogom"
              style={{ marginLeft: "10px" }}
            />
          </Link>
          <li
            className="bordernav"
            data-testid="navitem"
            style={{ textAlign: "center", marginBottom: "10px" }}
          >
            <hr style={{ margin: "10px", borderTop: "1px solid #263544" }} />
          </li>
          {userAttributes && (
            <>
              <NavItem
                link="/dashboard"
                name="Dashboard"
                id="dashboard"
                icon={faGlobe}
              >
                <NavItem
                  link="/dashboard"
                  name="Dashboard"
                  id="dashboard"
                  activeExclude={[
                    "/dashboard/performanceMetrics",
                    "/dashboard/changeLog",
                  ]}
                />
                <NavItem
                  link="/dashboard/performanceMetrics"
                  name="Performance Metrics"
                  id="performance_metrics"
                  activeExclude={["/dashboard/changeLog"]}
                />
                <NavItem
                  link="/dashboard/changeLog"
                  name="Change Log"
                  id="dashboard"
                />
              </NavItem>
              {filtered &&
                filtered.map((key, val) => {
                  switch (key.toLowerCase()) {
                    case "audits":
                      return (
                        <NavItem
                          link="/audits"
                          name="Audits"
                          id="audit_management"
                          icon={faListAlt}
                        >
                          <NavItem
                            link="/audits"
                            name="Audits"
                            id="audits"
                            activeExclude={[
                              "/audits/riskdomain",
                              "/audits/calendar",
                            ]}
                          />
                          <NavItem
                            link="/audits/calendar"
                            name="Calendar"
                            id="calendar"
                          />
                        </NavItem>
                      );
                    case "assessments":
                      return (
                        <NavItem
                          link="/assessments"
                          name="Assessments"
                          id="assessment"
                          icon={faClipboardList}
                        >
                          <NavItem
                            link="/assessments"
                            name="HIPAA Gap Assessment"
                            id="hippa_gap_assessment"
                            activeExclude={[
                              "/assessments/HIPAARiskAssessment",
                              "/assessments/assetManagement",
                            ]}
                          />
                          <NavItem
                            link="/assessments/HIPAARiskAssessment"
                            name="HIPAA Risk Assessment"
                            id="hipaa_risk_assessment"
                          />
                          <NavItem
                            link="/assessments/assetManagement"
                            name="Asset Management"
                            id="asset_management"
                          />
                        </NavItem>
                      );
                    case "issues":
                      return (
                        <NavItem
                          link="/issues"
                          name="Issues"
                          id="issue_management"
                          icon={faBug}
                        />
                      );

                    case "risk":
                      return (
                        <NavItem
                          link="/risk"
                          name="Risk"
                          id="risk"
                          icon={faRadiationAlt}
                        >
                          <NavItem
                            link="/risk"
                            name="Scenarios"
                            id="scenarios"
                            activeExclude={[
                              "/risk/comparison",
                              "/risk/portfolio",
                            ]}
                          />
                          <NavItem
                            link="/risk/comparison"
                            name="Comparisons"
                            id="comparisons"
                          />
                          <NavItem
                            link="/risk/portfolio"
                            name="Portfolios"
                            id="portfolios"
                          />
                        </NavItem>
                      );
                    case "actionplans":
                      return (
                        <NavItem
                          link="/actionplans"
                          name="Action Plans"
                          id="action_plans"
                          icon={faFirstAid}
                        />
                      );

                    case "system management":
                      return (
                        <NavItem
                          name="System Management"
                          link="/systemManagement/systemLists"
                          id="system_list"
                          icon={faCog}
                        >
                          {/* <NavItem
                            name="Performance metrics"
                            link="/systemManagement"
                            id="system_management"
                            activeExclude={["/systemManagement/systemLists"]}
                          /> */}
                          <NavItem
                            name="System Lists"
                            link="/systemManagement/systemLists"
                            id="system_list"
                          />
                        </NavItem>
                      );
                  }
                })}

              <li className="bordernav">
                <hr style={{ margin: "2px", borderTop: "1px solid #263544" }} />
              </li>
              {(permissions.organization.userRole === "admin" ||
                permissions.group.userRole === "admin") && (
                <NavItem
                  link="/administration"
                  name="Administration"
                  id="administration"
                  icon={faPeopleArrows}
                >
                  <NavItem
                    link="/administration"
                    name="Permissions"
                    id="permissions"
                    activeExclude={[
                      "/administration/organizationSetting",
                      "/administration/usageStats",
                      "/administration/systemSetting",
                    ]}
                  />
                  <NavItem
                    link="/administration/usageStats"
                    name="Usage Stats"
                    id="usage_stats"
                  />
                  {permissions.organization.userRole === "admin" && (
                    <>
                      <NavItem
                        link="/administration/organizationSetting"
                        name="Organization Setting"
                        id="organization_setting"
                      />
                      <NavItem
                        link="/administration/systemSetting"
                        name="System Settings"
                        id="system_settings"
                      />
                    </>
                  )}
                </NavItem>
              )}
            </>
          )}
        </ul>
      </div>
    </Nav>
  );
};

export default LeftNavBarV2;
