import React from "react";
import {
  Row, Col, Breadcrumb, Nav, 
} from "react-bootstrap";
import { useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import useSetTitle from "utils/useSetTitle";
import { RelatedItemReport } from "./PDF/RelatedItemReport";
import { ChangeLog } from "./Reports/Changelog";
import { SystemUsageReport } from "./Reports/SystemUsageReport";
import GenerateZip from "utils/zip-export/GenerateZip";
import { useGetIssuesInGroup } from "utils/connectors/issueConnectors";
import { issueReportSchema } from "../Issues/issueReportSchema";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { DateTime } from "luxon";
import { useGetActionPlansInGroup } from "utils/connectors/actionPlanConnectors";
import { actionPlanReportSchema } from "../ActionPlans/actionPlanReportSchema";

const ShowPDF = React.memo(() => <RelatedItemReport />);
export const Reporting = () => {
  const getFilename = useGenerateReportFilename();
  const { tab } = useParams<{ tab: string }>();
  useSetTitle("Summary Reports");
  const { issues } = useGetIssuesInGroup();
  const { actionPlans } =   useGetActionPlansInGroup();
  return (
    <>
      <Row className="dashboard-header" style={{ paddingBottom: 0 }}>
        {/* <Col xs="12">
          <Breadcrumb>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
        <Col xs="10">
          <h3>Summary Reports</h3>
        </Col>
        <Col xs="2">
          <GenerateZip
            fileName={{
              fileName: "Decipher Risk - Summary Report",
              extension: "zip",
            }}
            reports={[
              {
                fileName: getFilename({
                  type: "filename",
                  fileName: "Decipher Risk Summary - Issues",
                  timestamp: DateTime.now(),
                }),
                items: issues.filter((item) => item.archived !== true),
                schema: issueReportSchema(),
                type: "Multi",
              },
              {
                fileName: getFilename({
                  type: "filename",
                  fileName: "Decipher Risk Summary - Action Plans",
                  timestamp: DateTime.now(),
                }),
                items: actionPlans.filter((item) => item.archived !== true),
                schema: actionPlanReportSchema(),
                type: "Multi",
              },
            ]}
          />
        </Col>
        {/* <Nav
          variant="tabs"
          style={{ paddingLeft: "10px", display: "inline-flex" }}
        >
          <LinkContainer exact to="/reporting/summary">
            <Nav.Link>Summary Report</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/reporting/changelog">
            <Nav.Link>Change Log</Nav.Link>
          </LinkContainer>
        </Nav> */}
      </Row>
      <Row>
      <SystemUsageReport />
        {/* {(tab === "changelog" && <ChangeLog />) || <SystemUsageReport />} */}
      </Row>
    </>
  );
};
